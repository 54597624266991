import { graphql, useStaticQuery } from "gatsby"
import ContactBannerWrapper, {
  DetailsWrapper,
  Fields,
  NewsletterFormWrapper
} from "./contactBanner.style"

import { useDetectAdBlock } from "adblock-detect-react"
import React from "react"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import Container from "../../components/UI/Container"
import Form from "./form"

const ContactBanner = ({pathname}) => {
  const Data = useStaticQuery(graphql`
    query {
      alpackedJson {
        contactDetails {
          detail
          icon {
            publicURL
          }
        }
      }
    }
  `)

  const { contactDetails } = Data.alpackedJson
  const adBlockDetected = useDetectAdBlock()

  return (
    <ContactBannerWrapper>
      <Container width="1220px">
        <Fields>
          <Heading as="h2" content="Send us a Message" />
          <NewsletterFormWrapper>
            <Form adBlockDetected={adBlockDetected} pathname={pathname}/>
          </NewsletterFormWrapper>
        </Fields>
        <DetailsWrapper>
          <Heading as="h2" content="Contact Information" />
          <div>
            <ul>
              {contactDetails.map((item, index) => {
                return (
                  <li key={index}>
                    <img
                      loading="lazy"
                      src={item.icon.publicURL}
                      alt="contact details"
                    />
                    <Text content={item.detail} />
                  </li>
                )
              })}
            </ul>
          </div>
        </DetailsWrapper>
      </Container>
    </ContactBannerWrapper>
  )
}

export default ContactBanner
