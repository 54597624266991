import * as yup from "yup"

const validationsForm = {
  name: yup.string().required("Required"),
  company: yup.string().required("Required"),
  message: yup.string().required("Required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
}

export default validationsForm
