import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const ContactBannerWrapper = styled.div`
  position: relative;
  margin-top: -310px;

  @media (max-width: 1220px) {
    margin-top: -280px;
  }
  @media (max-width: 990px) {
    margin-top: -300px;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 990px) {
      flex-direction: column;
    }
    .reusecore__button {
      width: 50%;
      background-color: ${themeGet("colors.white")};
      color: ${themeGet("colors.mainBlue")};
      font-size: 20px;
      border-radius: 0px;
      padding: 20px 21px;
      text-transform: capitalize;
      margin-top: 40px;
      width: 100%;
      @media (max-width: 480px) {
        width: 100%;
      }
      &:hover {
        background-color: ${themeGet("colors.whiteHover")};
      }
    }

    @media (max-width: 990px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
`

export const Fields = styled.div`
  width: 55%;
  padding: 50px;
  background-color: ${themeGet("colors.mainBlue")};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  @media (max-width: 990px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    padding: 30px;
  }

  h2 {
    font-size: 28px;
    color: ${themeGet("colors.lightBlue")};
    margin-bottom: 40px;
    @media (max-width: 480px) {
      margin-bottom: 40px;
    }
  }
`

export const NewsletterFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;

  @media (max-width: 575px) {
    flex-direction: column;
    margin-bottom: 25px;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 45% 45%;
    grid-gap: 10%;
    @media (max-width: 990px) {
      grid-template-columns: 100%;
      grid-gap: 0%;
      width: 100%;
    }
  }
`

export const DetailsWrapper = styled.div`
  padding: 50px;
  background-color: ${themeGet("colors.lightBlue")};
  width: 45%;
  @media (max-width: 990px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    padding: 30px;
  }
  h2 {
    font-size: 28px;
    color: ${themeGet("colors.mainBlue")};
    margin-bottom: 70px;
    @media (max-width: 990px) {
      margin-bottom: 40px;
    }
  }

  li {
    display: flex;
    margin: 20px 0;
    p {
      color: ${themeGet("colors.mainBlue")};
      margin: 0;
    }
    img {
      width: 20px;
      margin-right: 20px;
    }
  }
`

export default ContactBannerWrapper
