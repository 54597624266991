import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  position: relative;
  padding-top: 80px;
  @media only screen and (max-width: 1220px) {
    padding-top: 60px;
  }
  @media only screen and (max-width: 990px) {
    padding-top: 60px;
  }
  @media only screen and (max-width: 480px) {
    padding-top: 50px;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  text-transform: uppercase;
  align-items: center;

  .home {
    font-size: 16px;
    font-weight: 700;
    color: ${themeGet("colors.black")};
    letter-spacing: 0.5px;
    margin: 0px;
    margin-right: 16px;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
      margin-right: 16px;
    }
  }

  img {
    margin-right: 16px;
    width: 10px;
    @media only screen and (max-width: 768px) {
      margin-right: 16px;
    }
  }

  .current-page {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: ${themeGet("colors.mainBlue")};
    margin: 0px;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
  }
`

export const Authors = styled.div`
  display: flex;
  flex-direction: row;
  margin: 64px 0 0 0;
  @media only screen and (max-width: 480px) {
    margin: 32px 0 0 0;
  }
  .current-page {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: ${themeGet("colors.mainBlue")};
    margin: 0 32px 0 0;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
  }
`

export default SectionWrapper
