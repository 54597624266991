import React from "react"
import { Link } from "gatsby"

import Arrow from "../../common/assets/image/alpacked/contacts/arrow.svg"
import AuthorsArrow from "../../common/assets/image/alpacked/blog/arrow.svg"
import Container from "../../components/UI/Container"
import Text from "../../components/Text"
import SectionWrapper, { Authors, Content } from "./breadcrumbs.style"

const Breadcrumbs = ({ page, authors }) => {
  return (
    <SectionWrapper>
      <Container width="1220px">
        <Content>
          <Link to="/">
            <Text content="HOME PAGE" className="home" />
          </Link>
          <img loading="lazy" src={Arrow} alt="breadcrumbs-arrow" />
          <Text content={page} className="current-page" />
        </Content>
        {authors && (
          <Authors>
            <Link to="/blog/authors">
              <Text content="OUR AUTHORS" className="current-page" />
            </Link>
            <img loading="lazy" src={AuthorsArrow} alt="breadcrumbs-arrow" />
          </Authors>
        )}
      </Container>
    </SectionWrapper>
  )
}

export default Breadcrumbs
