import SectionWrapper, { Content } from "./map.style.js"

import Container from "../../components/UI/Container"
import React from "react"

const Map = () => {
  return (
    <SectionWrapper>
      <Container width="1220px" className="container_wrapper">
        <Content>
          <div className="map">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2539.6864997925964!2d30.508663815767797!3d50.465562294156804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ce6bb8ef14b1%3A0x10e596b3eda7f408!2sNyzhnii%20Val%20St%2C%2017%2F8%2C%20Kyiv%2C%20Ukraine%2C%2002000!5e0!3m2!1sen!2sgr!4v1660645302310!5m2!1sen!2sgr"
              width="1000"
              height="300"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
            />
          </div>
        </Content>
      </Container>
    </SectionWrapper>
  )
}

export default Map
