import styled from "styled-components"

const SectionWrapper = styled.section`
  background: linear-gradient(180deg, #ffffff 55%, #000 45%);
  position: relative;
  padding: 77px 0 44px;
  @media only screen and (max-width: 991px) {
    padding: 36px 0 16px;
  }
`
export const Content = styled.div`
  margin-top: 80px;
  position: relative;
  max-height: 550px;

  .map {
    position: relative;
    padding-bottom: 100%; // This is the aspect ratio
    height: 0;
    overflow: hidden;
  }
  .map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 550px !important;
  }
  @media only screen and (max-width: 991px) {
    margin-top: 40px;
    width: 100%;
  }
`

export default SectionWrapper
