import React from "react"
import ContactHeroBanner from "../common/assets/image/alpacked/contacts/contacts-banner.jpg"
import SEO from "../components/SEO"
import { ContentWrapper } from "../containers/alpacked.style"
import Banner from "../containers/Banner"
import Breadcrumbs from "../containers/Breadcrumbs"
import ContactBanner from "../containers/ContactBanner"
import Layout from "../containers/Layout"
import Map from "../containers/Map"

const ContactPage = ({ location }) => {
  return (
    <>
      <SEO
        location={location}
        title="Alpacked DevOps Company"
        shortDesc="Contacts"
        description="Contact us to get more information on our DevOps services. ➤ Managed Services ➤ Outsourced team ➤ Consulting."
      />
      <Layout pathname="/contacts">
        <ContentWrapper>
          <Banner title="<h1>Get in touch</h1>" image={ContactHeroBanner} />
          <ContactBanner pathname="/contacts"/>
          <Breadcrumbs page="contacts" />
          <Map />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default ContactPage
